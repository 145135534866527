import React, { useEffect, useState } from 'react';

//  "proxy": "http://localhost:3000",

export const Widget3DTest = () => {

    let loadWASMIsDone = false;

    const loadWASM = async () => {

        console.log("useEffect called!");

        if (loadWASMIsDone === true)
            return;

        loadWASMIsDone = true;

        var xhr_pd = new XMLHttpRequest();
        xhr_pd.open("GET", process.env.PUBLIC_URL + "/wasm/webxr_app.wasm", true);
        xhr_pd.responseType = 'arraybuffer';

        xhr_pd.onload = function (e) {
            //console.log(xhr_pd.readyState);
            if (xhr_pd.readyState === 4) {
                if (xhr_pd.status === 200) {
                    //console.log(xhr_pd.status);
                    window.Module =
                    {
                        wasmBinary: xhr_pd.response,
                        preRun: [function () {
                            global.ENV.PROJECT_PATH = process.env.PUBLIC_URL + "/demo_project/";
                        }],
                        canvas: (function () {
                            var canvas = document.getElementById('gvrcanvas');
                            canvas.width = window.innerWidth;
                            canvas.height = window.innerHeight;
                            //canvas.width = 800;
                            //canvas.height = 600;
                            canvas.addEventListener("webglcontextlost", function (e) { alert('WebGL context lost. You will need to reload the page.'); e.preventDefault(); }, false);
                            canvas.focus();
                            return canvas;
                        })()
                    };

                    const script = document.createElement("script");
                    script.src = process.env.PUBLIC_URL + "wasm/webxr_app.js";
                    script.async = true;
                    document.body.appendChild(script);

                    window.addEventListener('resize', function () {
                        //window.Module.canvas.width = 800;
                        //window.Module.canvas.height = 600;
                        window.Module.canvas.width = window.innerWidth;
                        window.Module.canvas.height = window.innerHeight;
                        window.Module.canvas.dispatchEvent(new Event('resize'));
                    }, false);

                    window.Module.canvas.dispatchEvent(new Event('resize'));

                    window.shutdownGTVR = (function () {
                        //document.body.style.overflow = window.Module.gtvr.scrollbars;
                        delete window.Module;
                        delete window.shutdownGTVR;
                        global.location.reload();
                    });
                }
            }
        };

        xhr_pd.onerror = function (e) { console.error(xhr_pd.statusText); };
        xhr_pd.send(null);
    }

    useEffect(() => {
        loadWASM();
    }, []);

    return (
        <div>
            <canvas id="gvrcanvas" style={{ "background-color": "rgba(0, 0, 0, 1.0)", "z-index": "1000", "position": "absolute", "top": "0px", "left": "0px" }} onContextMenu={(event) => event.preventDefault()} />
        </div>
    );
} 
